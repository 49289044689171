import { graphql } from 'gatsby'
import RandomRecipe from '../components/randomRecipe'

export default RandomRecipe

export const pageQuery = graphql`
  query AllRecipesByTagQuery($slug: String!) {
    allStrapiRecipe(filter: { tags: { elemMatch: { slug: { eq: $slug } } } }) {
      edges {
        node {
          title
          slug
          tags {
            tag
          }
        }
      }
    }
    allStrapiTag(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          tag
        }
      }
    }
    allStrapiSuggestion {
      edges {
        node {
          suggestion
        }
      }
    }
  }
`
